
<template>
  <div class="body add-vehicle">
    <el-form
      :model="addForm"
      :rules="rules"
      :inline="true"
      ref="addForm"
      label-width="82px"
      label-position="left"
      class="demo-form-inline dialog-form"
    >
      <!-- 选择车辆： -->
      <el-form-item label="选择车辆：" prop="vehId">
        <car-tree @getData="getVehIds" :choosedCph="choosedCph"></car-tree>
      </el-form-item>
      <el-form-item label="合同编号：" prop="pactNo">
        <el-input
          v-model.trim="addForm.pactNo"
          placeholder="请输入合同编号"
        ></el-input>
      </el-form-item>

      <el-form-item label="包车类型：" prop="type">
        <el-select
          v-model="addForm.type"
          clearable
          placeholder="请选择包车类型"
        >
          <el-option
            v-for="i in modelList"
            :label="i.dictValue"
            :value="i.dictCode"
            :key="i.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="起始地点：" prop="startPosition">
        <el-input
          v-model.trim="addForm.startPosition"
          placeholder="请输入起始地点"
        ></el-input>
      </el-form-item>
      <el-form-item label="结束地点：" prop="endPosition">
        <el-input
          v-model.trim="addForm.endPosition"
          placeholder="请输入结束地点"
        ></el-input>
      </el-form-item>
      <!-- 开始时间： -->
      <el-form-item prop="startTime" label="开始时间：">
        <el-date-picker
          v-model="addForm.startTime"
          type="date"
          placeholder="选择日期时间"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </el-form-item>
      <!-- 结束时间： -->
      <el-form-item prop="endTime" label="结束时间：">
        <el-date-picker
          v-model="addForm.endTime"
          type="date"
          placeholder="选择日期时间"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="行程：" prop="journey">
        <el-input
          placeholder="请输入内容"
          v-model.trim="addForm.journey"
        ></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import carTree from '@/components/carTree/carSelectTree.vue'
import { formatDay, checkTimeEqualLimit } from '@/common/utils/index'

import { addJourney, editJourney, getJourneyById } from '@/api/lib/gps-api.js'
import { queryDictsByCodes } from '@/api/lib/api.js'

export default {
  components: {
    carTree
  },
  props: {
    itemId: {
      type: [Number, String]
    },
    cph: {
      type: String
    }
  },
  data() {
    let validateDate1 = (rule, value, callback) => {
      if (!checkTimeEqualLimit(value, this.addForm.endTime)) {
        callback(new Error('开始时间必须小于结束时间'))
      } else {
        callback()
      }
    }
    let validateDate2 = (rule, value, callback) => {
      if (!checkTimeEqualLimit(this.addForm.startTime, value)) {
        callback(new Error('开始时间必须小于结束时间'))
      } else {
        callback()
      }
    }
    return {
      choosedCph: '',
      timeLimit: 60,
      isEdit: false,
      addForm: {
        vehId: 1,
        pactNo: '',
        startPosition: '',
        endPosition: '',
        startTime: new Date(),
        endTime: new Date(),
        type: '',
        journey: ''
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              let now = new Date()
              let today =
                now - (now % (24 * 60 * 60 * 1000)) - 8 * 60 * 60 * 1000
              picker.$emit('pick', today)
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              let now = new Date()
              let oneDay = 1000 * 60 * 60 * 24
              let today =
                now - (now % (24 * 60 * 60 * 1000)) - 8 * 60 * 60 * 1000
              let date = today - oneDay
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick(picker) {
              let now = new Date()
              let oneDay = 1000 * 60 * 60 * 24
              let today =
                now - (now % (24 * 60 * 60 * 1000)) - 8 * 60 * 60 * 1000
              let date = today - oneDay * 7
              picker.$emit('pick', date)
            }
          }
        ]
      },
      rules: {
        vehId: [{ required: true, message: '请选择车辆', trigger: 'change' }],
        pactNo: [
          { required: true, message: '请输入合同编号', trigger: 'blur' }
        ],
        type: [{ required: true, message: '请选择行程类型', trigger: 'blur' }],
        startPosition: [
          { required: true, message: '请输入起始点', trigger: 'blur' }
        ],
        endPosition: [
          { required: true, message: '请输入结束点', trigger: 'blur' }
        ],
        journey: [{ required: true, message: '请输入行程', trigger: 'blur' }],
        startTime: [
          {
            type: 'date',
            required: true,
            message: '请选择日期',
            trigger: 'change'
          },
          { validator: validateDate1, trigger: 'change' }
        ],
        endTime: [
          {
            type: 'date',
            required: true,
            message: '请选择日期',
            trigger: 'change'
          },
          { validator: validateDate2, trigger: 'change' }
        ]
      },
      modelList: []
    }
  },
  methods: {
    // 获取车辆数据
    getVehIds(val) {
      this.$refs.addForm.clearValidate()
      this.addForm.vehId = val
    },
    // 新增/修改
    onSave() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.$emit('onLoading', true)
          const data = {
            ...this.addForm
          }
          data.startTime = `${formatDay(this.addForm.startTime)} 00:00:00`
          data.endTime = `${formatDay(this.addForm.endTime)} 23:59:59`

          delete data.time
          if (!this.isEdit) {
            addJourney(data).then((res) => {
              if (res.code === 1000) {
                this.$message.success('新增成功')
                this.clsoeDia(res.code === 1000)
              } else {
                this.$message.error(res.msg)
                this.$emit('onLoading', false)
              }
            })
          } else {
            data.id = this.addForm.id
            editJourney(data).then((res) => {
              if (res.code === 1000) {
                this.$message.success('修改成功')
                this.clsoeDia(res.code === 1000)
              } else {
                this.$message.error(res.msg)
                this.$emit('onLoading', false)
              }
            })
          }
        } else {
          return false
        }
      })
    },
    /** 取消 */
    clsoeDia(flag) {
      this.$emit('close', flag)
    },
    getDicts() {
      queryDictsByCodes({ parentCodes: 'BCLX' }).then((res) => {
        if (res.code === 1000) {
          this.modelList = res.data.BCLX
        }
      })
    }
  },
  created() {
    this.getDicts()
  },
  mounted() {
    if (this.itemId) {
      getJourneyById({ id: this.itemId }).then((res) => {
        if (res.code === 1000) {
          this.addForm = { ...res.data }
          this.addForm.startTime = new Date(res.data.startTime)
          this.addForm.endTime = new Date(res.data.endTime)
          delete this.addForm.createTime
          delete this.addForm.updateTime
          this.choosedCph = this.cph
        } else {
          this.$message.error(res.msg)
        }
      })
      this.isEdit = true
    } else {
      this.isEdit = false
    }
  },
  watch: {
    cph(curVal, oldVal) {
      this.choosedCph = this.cph
    }
  }
}
</script>

<style lang="scss" scoped>
.add-vehicle {
  @include themify() {
    .form-title {
      font-size: 14px;
      color: themed('n7');
      margin-bottom: 10px;
    }
    .form-title2 {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
