var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "body add-vehicle" },
    [
      _c(
        "el-form",
        {
          ref: "addForm",
          staticClass: "demo-form-inline dialog-form",
          attrs: {
            model: _vm.addForm,
            rules: _vm.rules,
            inline: true,
            "label-width": "82px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "选择车辆：", prop: "vehId" } },
            [
              _c("car-tree", {
                attrs: { choosedCph: _vm.choosedCph },
                on: { getData: _vm.getVehIds }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "合同编号：", prop: "pactNo" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入合同编号" },
                model: {
                  value: _vm.addForm.pactNo,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.addForm,
                      "pactNo",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addForm.pactNo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "包车类型：", prop: "type" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择包车类型" },
                  model: {
                    value: _vm.addForm.type,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm, "type", $$v)
                    },
                    expression: "addForm.type"
                  }
                },
                _vm._l(_vm.modelList, function(i) {
                  return _c("el-option", {
                    key: i.dictCode,
                    attrs: { label: i.dictValue, value: i.dictCode }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "起始地点：", prop: "startPosition" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入起始地点" },
                model: {
                  value: _vm.addForm.startPosition,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.addForm,
                      "startPosition",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addForm.startPosition"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "结束地点：", prop: "endPosition" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入结束地点" },
                model: {
                  value: _vm.addForm.endPosition,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.addForm,
                      "endPosition",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addForm.endPosition"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "startTime", label: "开始时间：" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "date",
                  placeholder: "选择日期时间",
                  "picker-options": _vm.pickerOptions
                },
                model: {
                  value: _vm.addForm.startTime,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "startTime", $$v)
                  },
                  expression: "addForm.startTime"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "endTime", label: "结束时间：" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "date",
                  placeholder: "选择日期时间",
                  "picker-options": _vm.pickerOptions
                },
                model: {
                  value: _vm.addForm.endTime,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "endTime", $$v)
                  },
                  expression: "addForm.endTime"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "行程：", prop: "journey" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入内容" },
                model: {
                  value: _vm.addForm.journey,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.addForm,
                      "journey",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addForm.journey"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }